import { Deserializable } from './deserializable.model';

import * as _moment from 'moment';
import { BaseModel } from './base.model';
import { CustomerModel } from './customer.model';
import { JobtypeModel } from './jobtype.model';
import { JoblocationModel } from './joblocation.model';
import { JobModel } from './job.model';
import { EventdayModel } from './eventday.model';
import { UserModel } from './user.model';
import { OfferletterModel } from './offerletter.model';
import { HiringmanagerModel } from './hiringmanager.model';
import { DATETIMES, EVENTCODE_TLD, EVENT_STATUSES, EVENT_TYPES } from './constants';
import { EventimageModel } from './eventimage.model';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class EventModel extends BaseModel implements Deserializable {
	// db columns
	id: string;
	customer_id: string;
	eventimage_id: string;
	eventmanager_id: string;
	localhr_id: string;
	hiringmanager_id: string;
  offerletter_id: string;
	name: string;
	eventcode: string;
	eventurl: string;
	teasertext: string;
	description: string;
	type: string;
	virtual: boolean;
	status: string;
	date_start: string;
	date_end: string;
	time_start: string;
	time_end: string;
	timezone: string;
	times: any;
	address_street: string;
	address_name: string;
	address_zip: string;
	address_city: string;
	address_state: string;
	address_country: string;
	address_additional: string;
	address_lat: number;
	address_lon: number;
	slots_lines_min: number;
	slots_lines_max: number;
	slots_lines: number;
	slots_length: number;
	slots_pause: number;
	slots_times: any;
	equipment: any;
	misc: any;
	offerletter_text: string;
  properties: any;
  valid: any;
  can: any;
	// pseudo properties
	header_image: string;
	url_title_image: string;
	candidate_linked: boolean;
	indeed_emails: number;
	// relations parent
	customer: CustomerModel;
	eventimage: EventimageModel;
	eventmanager: UserModel;
	localhr: any;
	hiringmanager: HiringmanagerModel;
  offerletter: OfferletterModel;
	// relations childs
	jobtypes: JobtypeModel[];
	joblocations: JoblocationModel[];
	jobs: JobModel[];
	eventdays: EventdayModel[];
	textmanagers: UserModel[];

	deserialize(input: any): this {
		Object.assign(this, input);
		// customer
		if (this.customer !== null && this.customer !== undefined) {
			this.customer = new CustomerModel().deserialize(this.customer);
		}
		// jobtypes
		let jobtypes: JobtypeModel[] = null;
		if (this.jobtypes !== null && this.jobtypes !== undefined) {
			jobtypes = [];
			this.jobtypes.forEach((jobtype: JobtypeModel) => {
				jobtypes.push(new JobtypeModel().deserialize(jobtype));
			});
		}
		this.jobtypes = jobtypes;
		// joblocations
		let joblocations: JoblocationModel[] = null;
		if (this.joblocations !== null && this.joblocations !== undefined) {
			joblocations = [];
			this.joblocations.forEach((joblocation: JoblocationModel) => {
				joblocations.push(new JoblocationModel().deserialize(joblocation));
			});
		}
		this.joblocations = joblocations;
		// jobs
		let jobs: JobModel[] = null;
		if (this.jobs !== null && this.jobs !== undefined) {
			jobs = [];
			this.jobs.forEach((job: JobModel) => {
				jobs.push(new JobModel().deserialize(job));
			});
		}
		this.jobs = jobs;
		// eventdays
		let eventdays: EventdayModel[] = null;
		if (this.eventdays !== null && this.eventdays !== undefined) {
			eventdays = [];
			this.eventdays.forEach((eventday: EventdayModel) => {
				eventdays.push(new EventdayModel().deserialize(eventday));
			});
		}
		this.eventdays = eventdays;
		// textmanagers
		let textmanagers: UserModel[] = null;
		if (this.textmanagers !== null && this.textmanagers !== undefined) {
			textmanagers = [];
			this.textmanagers.forEach((textmanager: UserModel) => {
				textmanagers.push(new UserModel().deserialize(textmanager));
			});
		}
		this.textmanagers = textmanagers;
		// localhr
		if (this.localhr !== null && this.localhr !== undefined) {
			this.localhr = new UserModel().deserialize(this.localhr);
		}
		// offerletter
		if (this.offerletter !== null && this.offerletter !== undefined) {
			this.offerletter = new OfferletterModel().deserialize(this.offerletter);
		}
		this.sortEventdays();
		return this;
	}

	// properties

	get event_mail(): string {
		return this.eventcode + EVENTCODE_TLD;
	}

	get date_full(): string {
		if (this.date_start === null || this.date_end === null || typeof this.times !== 'object') {
			return 'N/A';
		}
    const date_start = this.date_start.substring(0,10);
    const date_end = this.date_end.substring(0,10);
		if (moment(date_start).isSame(moment(date_end), 'day')) {
			const entry = this.times.times[0];
			const start = moment(date_start + ' ' + entry.time_start);
			const end = moment(date_end + ' ' + entry.time_end);
			if (!start.isValid() || !end.isValid()) {
				return 'N/A';
			}
			return start.format(DATETIMES.DATE_LONG) + '<br>' + start.format(DATETIMES.TIME_SHORT) + ' - ' + end.format(DATETIMES.TIME_SHORT);
		} else if (this.times.mode === 'same') {
			const entry = this.times.times[0];
			const start = moment(date_start + ' ' + entry.time_start);
			const end = moment(date_end + ' ' + entry.time_end);
			if (!start.isValid() || !end.isValid()) {
				return 'N/A';
			}
			return start.format(DATETIMES.DATE_SHORT) + ' - ' + end.format(DATETIMES.DATE_LONG) + '<br>' + start.format(DATETIMES.TIME_SHORT) + ' - ' + end.format(DATETIMES.TIME_SHORT);
		} else {
			const days: any[] = [];
			let dayCount = 0;
			this.times.times.forEach((entry: any) => {
				const start = moment(date_start + ' ' + entry.time_start).add(dayCount, 'day');
				const end = moment(date_end + ' ' + entry.time_end).add(dayCount, 'day');
				if (start.isValid() && end.isValid()) {
					days.push(start.format(DATETIMES.DATE_LONG) + ': ' + start.format(DATETIMES.TIME_SHORT) + ' - ' + end.format(DATETIMES.TIME_SHORT));
				}
				dayCount++;
			});
			return days.join('<br>');
		}
	}

	get date_short_dateonly(): string {
		const start = moment(this.date_start);
		const end = moment(this.date_end);
		if (!start.isValid() || !end.isValid()) {
			return 'N/A';
		}
		if (start.isSame(end, 'day')) {
			return start.format(DATETIMES.DATE_LONG);
		} else {
			return start.format(DATETIMES.DATE_SHORT) + ' - ' + end.format(DATETIMES.DATE_LONG);
		}
	}

	get address_cityzipstate(): string {
		if (this.isVirtual() === true) {
			return '(virtual)';
		} else {
      return this.getPhysicalAddress();
		}
	}

  get address_website(): string {
    if (this.isVirtual() === true) {
      return this.getJoblocationAddresses(true);
    } else {
      return this.getPhysicalAddress(true);
    }
  }

	get is_published() {
		return this.status === 'online';
	}

	get is_published_or_after() {
		return this.status === 'online' || this.is_standby === true || this.is_running === true || this.is_finished === true;
	}

	get is_standby() {
		return this.status === 'standby';
	}

	get is_standby_or_after() {
		return this.status === 'standby' || this.is_running === true || this.is_finished === true;
	}

	get is_running() {
		return this.status === 'running';
	}

	get is_running_or_after() {
		return this.status === 'running' || this.status === 'finished' || this.status === 'archived';
	}

	get is_finished() {
		return this.status === 'finished';
	}

	get is_archived() {
		return this.status === 'archived';
	}

	get is_startable(): boolean {
		if (this.eventdays !== null && this.eventdays.length > 0) {
			return this.isEventdayStartable(this.eventdays[0]);
		}
		return false;
	}

	get selectable_name(): string { // for admin/debug
		return this.name + ' - ' + this.date_short_dateonly;
	}

	get status_color(): string {
		if (this.is_running === true) {
			return '#f2a500';
		} else if (this.is_finished === true) {
			return '#a0a0a0';
		} else if (this.is_published === true) {
			return 'green';
		}
    return '';
	}

	get type_name(): string {
		return EVENT_TYPES[this.type];
	}

	get status_name(): string {
		return EVENT_STATUSES[this.status];
	}

	// methods

	isVirtual(): boolean {
		return !this.is_candidate_day && this.virtual === true;
	}

	sortEventdays() {
		if (this.eventdays !== null && this.eventdays.length > 0) {
			this.eventdays.sort((a: EventdayModel, b: EventdayModel) => {
				const first = moment(a.date_start);
				return first.isAfter(b.date_start) ? 1 : ((first.isBefore(b.date_start)) ? -1 : 0);
			});
		}
	}

	isValid(section: string): boolean {
    return (this.valid) ? this.valid[section] === true : false;
	}

  isPossible(func: string): boolean {
    if (this.can) {
      return this.can[func];
    }
    return false;
  }

	isEventdayStartable(eventday: EventdayModel): boolean {
		const day_date = moment(eventday.date_start);
		let daysBeforeFinished = true;
		this.eventdays.forEach((compare: EventdayModel) => {
			if (day_date.isAfter(compare.date_start) && compare.status !== 'finished' && !this.is_searay && compare.hasSlotsTimes()) {
				daysBeforeFinished = false;
			}
		});
		return daysBeforeFinished === true && (eventday.status === 'setup' || eventday.status === 'standby') && eventday.hasSlotsTimes();
	}

  getDateOfDayNumber(day: number): any {
    return moment(this.date_start).add(day, 'days');
  }

  getDownloadFilename(): string {
		return this.name.replace(/\s/g, '_').replace(/[^a-zA-Z_]/g, '') + '_' + moment(this.date_start).format(DATETIMES.DATE_REVERSE);
	}

	// model control

	get is_candidate_day(): boolean {
		return this.type === 'candidate-day';
	}

	get is_candidate_friday(): boolean {
		return this.type === 'candidate-friday';
	}

	get is_searay(): boolean {
		return this.type === 'searay';
	}

	get is_previewable(): boolean {
		return this.status === 'setup';
	}

	get is_ready(): boolean {
		return this.is_published === true;
	}

	get is_publishable(): boolean {
		return this.status === 'setup' && this.isValid('general') && this.isValid('jobs') && this.isValid('interview');
	}

	get is_unpublishable(): boolean {
		return this.status === 'online';
	}

	get is_finishable(): boolean {
		return this.status === 'running' && this.eventdays[this.eventdays.length - 1].status === 'running';
	}

	get is_editable(): boolean {
		return true;
	}

  protected getJoblocationAddresses(web = false): string {
    const div = (web === true) ? '<br>' : ', ';
    let addresses: Array<string> = [];
    this.joblocations.forEach((joblocation: JoblocationModel) => {
      addresses.push(joblocation.address_cityzipstate);
    });
    return addresses.join(div);
  }

  protected getPhysicalAddress(web = false): string {
    const div = (web === true) ? '<br>' : ', ';
    const components = [];
    const zip = [];
    if (this.address_city !== null) {
      components.push(this.address_city);
    }
    if (this.address_state !== null) {
      zip.push(this.address_state);
    }
    if (this.address_zip !== null) {
      zip.push(this.address_zip);
    }
    if (zip.length > 0) {
      components.push(zip.join(' '));
    }
    return components.join(div);
  }

}

