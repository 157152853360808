<ng-container *transloco="let t;">
<div *ngIf="event !== null">

	<div *ngIf="mode === 'home-teaser'" class="home-teaser">
		<div class="uk-background-cover uk-height-medium uk-panel teaser-image" [style.backgroundImage]="'url('+ headerImage +')'" *ngIf="event !== null">
			<div class="uk-overlay uk-position-bottom">
				<div class="uk-container">
					<h1>{{ t('partials.event.next_event') }}</h1>
					<h2>{{event.name}}</h2>
				</div>
			</div>
		</div>

		<div class="uk-section uk-section-small next-event-details" *ngIf="event !== null">
			<div class="uk-container">
				<div uk-grid class="uk-grid-divider uk-child-width-1-3@m">
					<div>
						<p class="h3-icon"><span uk-icon="icon: location; ratio: 1.4"></span></p>
						<h3 *ngIf="!event.isVirtual()">{{ t('partials.event.location_event') }}</h3>
						<h3 *ngIf="event.isVirtual()">{{ t('partials.event.joblocations') }}</h3>
						<p [innerHtml]="event.address_website"></p>
					</div>
					<div>
						<p class="h3-icon"><span uk-icon="icon: calendar; ratio: 1.4"></span></p>
						<h3>{{ t('partials.event.date_of_event') }}</h3>
						<p [innerHtml]="event.date_full"></p>
					</div>
					<div>
						<p><button class="uk-button kn-button uk-width-1-1" (click)="openDetails();">{{ t('partials.event.en_savoir_plus') }}</button></p>
						<p *ngIf="loggedIn === true">
							<button *ngIf="event.candidate_linked !== true" class="uk-button kn-call-to-action uk-width-1-1" (click)="addToEventsList()">{{ t('partials.event.add_to_my_interview_days') }}</button>
							<button *ngIf="event.candidate_linked === true" class="uk-button kn-call-to-action uk-width-1-1" (click)="requestRemoveFromEventList()">{{ t('partials.event.remove_from_my_interview_days') }}</button>
						</p>
						<p *ngIf="loggedIn !== true">
							<button *ngIf="event.candidate_linked !== true" class="uk-button kn-call-to-action uk-width-1-1" (click)="openDetails('registration')">{{ t('partials.event.register_now') }}</button>
						</p>
					</div>
				</div>
				<hr>
				<p *ngIf="event.teasertext">{{event.teasertext}}</p>
				<p *ngIf="event.description">{{event.description}}</p>
				<div uk-grid class="uk-grid-divider">
					<div class="uk-width-1-2@m">
						<p class="headline">{{ t('partials.event.open_positions') }}</p>
						<ul class="uk-list link-list">
							<li *ngFor="let jobtype of event.jobtypes"><a (click)="showJobType(jobtype)">{{jobtype.title}} <span uk-icon="icon: info; ratio: 0.7" class="pointer" [title]="t('partials.event.view_detailed_job_description')"></span></a></li>
						</ul>
					</div>
					<div class="uk-width-1-2@m" *ngIf="!event.isVirtual()">
						<p class="headline">{{ t('partials.event.joblocations') }}</p>
						<ul class="uk-list link-list">
							<li *ngFor="let joblocation of event.joblocations"><a (click)="showJobLocation(joblocation)">{{joblocation.address_city}}, {{joblocation.address_state}} {{joblocation.address_zip}} <span uk-icon="icon: info; ratio: 0.7" class="pointer" [title]="t('partials.event.see_location_on_map')"></span></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="mode === 'home-card'" class="home-card">
		<div class="uk-card uk-card-hover uk-card-default uk-card-small">
			<div class="uk-card-media-top pointer" (click)="openDetails()">
				<div class="uk-inline" style="min-width: 100%;min-height: 40px;">
					<img src="{{ headerImage }}" alt="">
					<div class="uk-overlay uk-position-bottom">
						<p>{{event.name}}</p>
					</div>
				</div>
			</div>
			<div class="uk-card-body">
				<div uk-grid class="uk-grid-small uk-child-width-1-2@m pointer" (click)="openDetails()">
					<div>
						<p class="h3-icon"><span uk-icon="icon: location; ratio: 1.4"></span></p>
						<h3 *ngIf="!event.isVirtual()">{{ t('partials.event.location_event') }}</h3>
						<h3 *ngIf="event.isVirtual()">{{ t('partials.event.joblocations') }}</h3>
						<p [innerHtml]="event.address_website"></p>
					</div>
					<div>
						<p class="h3-icon"><span uk-icon="icon: calendar; ratio: 1.4"></span></p>
						<h3>{{ t('partials.event.date_of_event') }}</h3>
						<p [innerHtml]="event.date_short_dateonly"></p>
					</div>
				</div>
				<hr>
					<p><button class="uk-button kn-button uk-width-1-1" (click)="openDetails();">{{ t('partials.event.en_savoir_plus') }}</button></p>
					<app-messagelist [group]="'event' + event.id"></app-messagelist>
					<p *ngIf="loggedIn === true">
						<button *ngIf="event.candidate_linked !== true" class="uk-button kn-call-to-action uk-width-1-1" (click)="addToEventsList()">{{ t('partials.event.add_to_my_interview_days') }}</button>
						<button *ngIf="event.candidate_linked === true" class="uk-button kn-call-to-action uk-width-1-1" (click)="requestRemoveFromEventList()">{{ t('partials.event.remove_from_my_interview_days') }}</button>
					</p>
					<p *ngIf="loggedIn !== true">
						<button *ngIf="event.candidate_linked !== true" class="uk-button kn-call-to-action uk-width-1-1" (click)="openDetails('registration')">{{ t('partials.event.register_now') }}</button>
					</p>
				<hr>
				<p *ngIf="event.teasertext">{{event.teasertext}}</p>
				<hr>
				<div uk-grid class="uk-grid-small" [ngClass]="{'uk-child-width-1-2@m': !event.isVirtual()}">
					<div>
						<p class="headline">{{ t('partials.event.jobs_offers') }}</p>
						<ul class="uk-list link-list">
							<li *ngFor="let jobtype of event.jobtypes"><a (click)="showJobType(jobtype)">{{jobtype.title}} <span uk-icon="icon: info; ratio: 0.7" class="pointer" [title]="t('partials.event.view_detailed_job_description')"></span></a></li>
						</ul>
					</div>
					<div *ngIf="!event.isVirtual()">
						<p class="headline">{{ t('partials.event.joblocations') }}</p>
						<ul class="uk-list link-list">
								<li *ngFor="let joblocation of event.joblocations"><a (click)="showJobLocation(joblocation)">{{joblocation.address_city}} <span uk-icon="icon: info; ratio: 0.7" class="pointer" [title]="t('partials.event.see_location_on_map')"></span></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>

</ng-container>
