import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { catchError } from 'rxjs/operators';

export class ApiCoreService {

	environment: any = null;
	authService: any = null;
	mode: string = null;

	constructor(
		public http: HttpClient,
		mode: string,
		environment: any
	) {
		this.mode = mode;
		this.environment = environment;
	}

	postResource(model: string, id: string, type: string, payload: any): Observable<any> {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');
		return this.http.post<any>(this.getUrl('core/resource/' + model + '/' + id + '/' + type), payload, {
			headers: headers,
			reportProgress: true,
			observe: 'events'
		}).pipe(
			catchError(err => {
				return throwError(this.getError(err));
			})
		);

	}

	deleteResource(model: string, id: string, type: string): Observable<any> {
		return this.http.delete<any>(this.getUrl('core/resource/' + model + '/' + id + '/' + type)).pipe(
			catchError(err => {
				return throwError(this.getError(err));
			})
		);

	}

	getUrl(route: string): string {
		let url = '';
		if (this.environment !== null) {
			url += this.environment.serverUrl;
		} else if (isDevMode()) {
			url += 'http://backend.smartblue-es.local'; // @todo: IMAGES
		}
		url += '/api';
		if (this.mode !== null) {
			url += '/' + this.mode;
		}
		url += '/' + route;
		return url;
		// return ((this.environment !== null) ? this.environment.serverUrl : '') + '/api/' + this.mode + '/' + route;
	}

	getError(err: any): any {
		if (err.status === 401) {
      if (this.authService !== null) {
			  this.authService.setLoggedOut();
      }
		}
		const fields: any = {};
		let common = [];
		const errors = err.error;
		for (const key in errors) {
			if (!errors.hasOwnProperty(key)) {
				continue;
			}
			if (key === 'common') {
				common = errors[key];
			} else {
				fields[key] = errors[key];
			}
		}
		return {
			status: err.status,
			common: common,
			fields: fields
		};
	}

}
