// @i18n

export const EVENTCODE_TLD = '@kncandidateday.es';
export const COUNTRY_NAME = 'España';
export const COUNTRY_SHORTCUT = 'ES';

export const MAX_EVENT_LENGTH = 14;

export const DATETIMES: any = {
  DATE_SHORT: 'DD MMM',
  DATE_LONG: 'DD MMM YYYY',
  DATE_MONTH_YEAR: 'MMM YYYY',
  DATE_REVERSE: 'YYYY-MM-DD',
  TIME_SHORT: 'HH:mm',
  TIME_LONG: 'HH:mm:ss',
  DATETIME_SHORT: 'DD MMM, HH:mm',
  DATETIME_LONG: 'DD MMM YYYY, HH:mm',
  DATETIME_FULL_REVERSE: 'YYYY-MM-DD HH:mm:ss',
  DATE_PRIMEPICKER_LONG: 'dd M yy',
  DATE_PRIMEPICKER_MONTH_YEAR: 'y',
  HOUR_FORMAT: 24,
  FIRST_DAY_OF_WEEK: 1
}

export const USER_ROLES: any = {
	administrator: 'Administrador',
	genius: 'Genius',
	recruiter: 'Reclutador',
	'registration-clerk': 'Empleado de registro',
	'back-office': 'Oficina de apoyo',
	management: 'Gerencia',
};

export const META_ROLES: any = {
	user: 'Usuario',
	admin: 'Administrador',
	superuser: 'SuperUser'
};

export const CURRENCIES_SHORT: any = {
	USD: '$',
	GBP: '£',
	EUR: '€'
}

export const EVENT_TYPES: any = {
	'candidate-friday': 'Event KN Spain',
};

export const EVENT_STATUSES: any = {
	setup: 'Configuración',
	standby: 'En espera',
	online: 'En línea',
	running: 'En curso',
	finished: 'Finalizada',
	archived: 'Archivado',
};

export const EVENTDAY_STATUSES: any = {
	setup: 'Configuración',
	standby: 'En espera',
	running: 'En curso',
	finished: 'Finalizada',
	archived: 'Archivado',
};

export const CANDIDATEEVENT_STATUSES: any = {
	registered: 'Registrada',
	noshow: 'No se presentó',
	interview: 'Entrevista en curso',
	finished: 'Finalizada',
};

export const CANDIDATEEVENT_INTERVIEW_STATUSES: any = {
	hired: 'Contratado',
	pooled: 'Guardado',
	rejected: 'Rechazado',
	declined: 'Declinado',
};

export const CANDIDATEEVENT_LISTSTATUSES: any = {
	registered: 'Registrada',
	ready: 'Preparada',
  scheduled: 'Entrevista concertada',
	noshow: 'No se presentó',
	interview: 'Entrevista en curso',
	finished: 'Finalizada',
};

export const REASONS: any = {
	pooled: [
    'La ubicación del puesto de trabajo y la fecha de inicio no se ajustan al candidato',
    'El perfil de empleo ideal del reclutador no está disponible',
    'El perfil de empleo ideal del candidato no está disponible',
    'El salario al que aspira el candidato es demasiado elevado',
	],
	rejected: [
    'No cumple los requisitos mínimos generales',
    'No cumple los requisitos mínimos de ubicación',
    'No cumple los requisitos mínimos de experiencia previa',
    'No cumple los requisitos mínimos de experiencia pertinente',
    'Los requisitos salariales son demasiado elevados',
	],
	declined: [
		'No deseo responder',
    'Razones personales o familiares',
    'Los requisitos salariales son demasiado bajos',
    'Las ventajas no me convencen',
    'El horario no es el adecuado: horario de trabajo, turno, etc.',
    'Prefiero quedarme con el empleador actual',
    'El lugar de trabajo está demasiado lejos',
    'La ubicación del puesto de trabajo no me convence',
	],
};

export const WORKFORCE: any = {
	'Blue collar': 'Blue collar',
	'White collar': 'White collar',
};

export const WORKFORCE_LEVEL: any = {
	1: 1,
	2: 2,
	3: 3,
};

export const JOBTYPE_STATUS: any = {
	employee: 'Empleado/a',
};

export const CONTRACT_TYPES: any = {
  'Temporal': 'Temporal',
  'Indefinido': 'Indefinido',
  'Fijo - Discontinuo': 'Fijo - Discontinuo',
}

export const WORKING_TIMES: any = {
  'Completa': 'Completa',
  'Parcial': 'Parcial'
}

export const PROFESSIONAL_GROUPS: any = {
  'Almacenero': 'Almacenero',
  'Capataz': 'Capataz',
  'Carretillero': 'Carretillero',
  'Chofer': 'Chofer',
  'Conductor': 'Conductor',
  'Empleado': 'Empleado',
  'Empleado Auxiliar': 'Empleado Auxiliar',
  'Encargado': 'Encargado',
  'Encargado Almacén': 'Encargado Almacén',
  'Encargardo General': 'Encargardo General',
  'Jefe Área': 'Jefe Área',
  'Jefe Equipo': 'Jefe Equipo',
  'Jefe Taller': 'Jefe Taller',
  'Mozo Especialista': 'Mozo Especialista',
  'Mozo Ordinario': 'Mozo Ordinario',
  'Oficial 1ª Taller': 'Oficial 1ª Taller',
  'Oficial 2ª Taller': 'Oficial 2ª Taller',
  'Oficial 3ª Taller': 'Oficial 3ª Taller',
  'Operario': 'Operario',
  'Operario Auxiliar': 'Operario Auxiliar',
  'Técnico Auxiliar': 'Técnico Auxiliar',
  'Titulado Técnico': 'Titulado Técnico',
  'Verificadro': 'Verificadro',
  'Vigilante': 'Vigilante',
}
