<ng-container *transloco="let t;">
    <app-event *ngIf="nextEvent !== null" [mode]="'home-teaser'" [event]="nextEvent"></app-event>

    <div class="uk-container">

        <div class="uk-section uk-section-large" *ngIf="loading === true">
            <p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
            <p class="uk-text-center">
                {{ t('home.loading_events') }}
            </p>
        </div>

        <div class="uk-section uk-section-large" *ngIf="loading !== true && ((nextEvent === null && app.showTopEvent() === true) || (events.length === 0 && app.showTopEvent() === false))">
            <p class="uk-text-center"><span uk-icon="icon: info; ratio: 3"></span></p>
            <p class="uk-text-center">
                {{ t('home.no_events') }}
            </p>
        </div>

        <div class="uk-section uk-section-small event-list" *ngIf="events !== null && events.length > 0">
            <h2><span uk-icon="icon: calendar"></span> {{ t('home.upcoming_events') }}</h2>

            <div uk-grid="masonry: true" class="uk-child-width-1-3@m uk-child-width-1-2@s">

                <div *ngFor="let event of events">
                    <app-event [mode]="'home-card'" [event]="event"></app-event>
                </div>

            </div>
        </div>

    </div>
</ng-container>
